<template>
  <Expand
    type="register"
    transition="slide-left"
    :class="{ 'is-success': isSuccess }">
    <template v-slot:top>
      <h2 v-if="!isSuccess">Register</h2>
    </template>
    <template v-slot:content>
      <template v-if="isSuccess">
        <h3 class="h4">You've successfully registered and been logged in!</h3>
        <div class="form">
          <div class="form__row form__row--button">
            <div class="form__field">
              <Button
                text="Close"
                @click="closeHeaderMenu"
                :isAltBG="true"
                :hasClose="true" />
            </div>
            <div class="form__field">
              <ArrowLink to="/account" text="Go To My Account" />
              <small>Closing in {{ successCountdownNum }}</small>
            </div>
          </div>
        </div>
      </template>
      <form
        v-else
        class="form"
        :class="{ 'has-errors': $v.$anyError }"
        @submit.prevent="handleSubmit">
        <div class="form__row">
          <div
            class="form__field form__field--half form__field--medium"
            :class="{ 'has-error': $v.first_name.$error }">
            <label class="accessible" for="first_name">
              First Name (Required)
            </label>
            <input
              id="first_name"
              name="first_name"
              type="text"
              placeholder="First Name"
              v-model.trim="first_name" />
            <span
              class="error"
              v-if="$v.first_name.$error && !$v.first_name.required">
              This field is required
            </span>
          </div>

          <div
            class="form__field form__field--half form__field--medium"
            :class="{ 'has-error': $v.last_name.$error }">
            <label class="accessible" for="last_name">
              Last Name (Required)
            </label>
            <input
              id="last_name"
              name="last_name"
              type="text"
              placeholder="Last Name"
              v-model.trim="last_name" />
            <span
              class="error"
              v-if="$v.last_name.$error && !$v.last_name.required">
              This field is required
            </span>
          </div>
        </div>

        <div class="form__row">
          <div
            class="form__field form__field--medium"
            :class="{ 'has-error': $v.email.$error }">
            <label class="accessible" for="email">Email (Required)</label>
            <input
              id="email"
              name="email"
              type="text"
              placeholder="Email"
              v-model.trim="email" />
            <span class="error" v-if="$v.email.$error && !$v.email.required">
              This field is required
            </span>
            <span class="error" v-if="$v.email.$error && !$v.email.email">
              Please enter a valid email address
            </span>
          </div>
        </div>

        <div class="form__row">
          <div
            class="form__field form__field--medium"
            :class="{ 'has-error': $v.password.$error }">
            <label class="accessible" for="password">Password (Required)</label>
            <input
              id="password"
              name="password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Password"
              v-model.trim="password" />
            <span
              class="error"
              v-if="$v.password.$error && !$v.password.required">
              This field is required
            </span>
            <span
              class="error"
              v-if="$v.password.$error && !$v.password.minLength">
              Your password must be a minimum of
              {{ $v.password.$params.minLength.min }} characters long
            </span>
          </div>
        </div>

        <div class="form__row">
          <div
            class="form__field form__field--medium"
            :class="{ 'has-error': $v.confirm_password.$error }">
            <label class="accessible" for="confirm_password">
              Confirm Password (Required)
            </label>
            <input
              id="confirm_password"
              name="confirm_password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Confirm Password"
              v-model.trim="confirm_password" />
            <span
              class="error"
              v-if="
                $v.confirm_password.$error && !$v.confirm_password.required
              ">
              This field is required
            </span>
            <span
              class="error"
              v-if="
                $v.confirm_password.$error && !$v.confirm_password.minLength
              ">
              Your password must be a minimum of
              {{ $v.confirm_password.$params.minLength.min }} characters long
            </span>
            <span
              class="error"
              v-if="$v.confirm_password.$error && !$v.confirm_password.sameAs">
              Your passwords do not match
            </span>
          </div>
        </div>

        <div class="form__row">
          <div class="form__field">
            <input
              id="show_password"
              type="checkbox"
              name="show_password"
              v-model="showPassword"
              value="true" />
            <label for="show_password">Show Password?</label>
          </div>
        </div>

        <div class="form__row">
          <div class="form__field">
            <fieldset>
              <legend class="accessible">Newsletter Opt-In</legend>
              <input
                id="newsletter_opt_in"
                name="newsletter_opt_in"
                type="checkbox"
                v-model="newsletter_opt_in" />
              <label for="newsletter_opt_in">
                Yes, I would like to receive the Gee Beauty newsletter
              </label>
            </fieldset>
          </div>
        </div>

        <div class="form__row form__row--button">
          <div class="form__field">
            <Button type="submit" :text="buttonText" />
          </div>
          <div class="form__field">
            <span>
              Already have an account?
              <ArrowLink
                @click="openHeaderMenuExpand('login')"
                text="Log In"
                :isFakeButton="true" />
            </span>
          </div>
        </div>

        <FormFeedback
          :showFeedback="showFeedback"
          :submitState="submitState"
          :submitMessage="submitMessage" />
      </form>
    </template>
  </Expand>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'

  import { createCustomer } from '@/shopify/customer'

  import formMixin from '@/mixins/formMixin'
  import loginMixin from '@/mixins/loginMixin'

  import Expand from '@/components/header/Expand'
  import Button from '@/components/global/Button'
  import ArrowLink from '@/components/global/ArrowLink'
  import FormFeedback from '@/components/global/FormFeedback'

  export default {
    name: 'Register',

    mixins: [formMixin, loginMixin],

    components: {
      Expand,
      Button,
      ArrowLink,
      FormFeedback,
    },

    data() {
      return {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        confirm_password: null,
        showPassword: false,
        newsletter_opt_in: false,
        defaultButtonText: 'Register',
        successCountdownNum: 5,
      }
    },

    computed: {
      ...mapState({
        locale: state => state.locale,
        activeMenu: state => state.header.activeMenu,
        preCheckRegisterNewsletter: state =>
          state.header.preCheckRegisterNewsletter,
      }),

      isSuccess() {
        return this.submitState == 'success'
      },
    },

    validations: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(7),
      },
      confirm_password: {
        required,
        minLength: minLength(7),
        sameAs: sameAs('password'),
      },
    },

    watch: {
      activeMenu(newVal) {
        if (newVal == 'register') {
          this.newsletter_opt_in = this.preCheckRegisterNewsletter
        }
      },

      submitState(newVal) {
        if (newVal == 'success') {
          setInterval(() => {
            this.successCountdownNum -= 1

            if (this.successCountdownNum == 0) {
              this.closeHeaderMenu()
            }
          }, 1000)
        }
      },
    },

    methods: {
      ...mapActions(['openHeaderMenuExpand', 'closeHeaderMenu']),

      async handleSubmit() {
        this.$v.$touch()
        this.showFeedback = false
        this.isSubmitting = true

        if (this.$v.$invalid) {
          this.handleFormFeedback('error')
          return
        }

        await this.createCustomerInShopify()

        if (this.newsletter_opt_in) {
          this.$klaviyo.addToDefaultList(this.locale, this.email)
        }

        this.handleFormFeedback('success', 'You have successfully registered!')

        this.$pinterest.signUp()

        this.logIn()
      },

      async createCustomerInShopify() {
        const { data } = await this.$shopify.client.mutate({
          mutation: createCustomer,
          variables: {
            input: {
              email: this.email,
              firstName: this.sanitize(this.first_name),
              lastName: this.sanitize(this.last_name),
              password: this.password,
              acceptsMarketing: this.newsletter_opt_in,
            },
          },
        })

        const { customerCreate } = data

        if (customerCreate.customerUserErrors.length) {
          const { message } = customerCreate.customerUserErrors[0]

          this.handleFormFeedback('error', message)
          throw new Error(message)
        }

        return customerCreate.customer.id
      },

      sanitize(str) {
        return str
          .split(' ')
          .map(el => el.charAt(0).toUpperCase() + el.slice(1))
          .join(' ')
      },
    },
  }
</script>
